






import { Component, Vue } from "vue-property-decorator";

// Components
import { HelpIcon } from "@components";
import { GamePage, Minesweeper as MinesweeperGame } from "@components/Games";

// Utilities
import games from "@/games";

@Component({
  components: {
    GamePage,
    HelpIcon,
    MinesweeperGame,
  },
})
export default class Minesweeper extends Vue {
  game = games.minesweeper;
}
